export enum CardOptions {
  location = 'locationKey',
  area = 'areaKey',
  consumable = 'consumableKey',
  createEquipment = 'createEquipmentKey',
  createPurchase = 'createPurchaseKey',
  completeWorkOrder = 'completeWorkOrder',
  crew = 'crewKey',
  dashboardEditor = 'dashboardEditorKey',
  dashboardWidgetEditorComponent = 'dashboardWidgetEditorComponentKey',
  default = 'defaultKey',
  dispatch = 'dispatchKey',
  dispatchAuto = 'dispatchAutoKey',
  equipment = 'equipmentKey',
  equipmentHistory = 'equipmentHistoryKey',
  equipmentOptions = 'equipmentOptionsKey',
  equipmentReview = 'equipmentReviewKey',
  facility = 'facilityKey',
  facilitySummary = 'summaryKey',
  formData = 'formDataKey',
  finishedWorkOrder = 'finishedWorkOrderKey',
  finishedWorkOrderLine = 'finishedTaskKey',
  foreman = 'foremanKey',
  foremanUnscheduled = 'foremanUnscheduledKey',
  item = 'itemKey',
  invoiceFilters = 'invoiceFiltersKey',
  invoiceSelectedLines = 'invoiceSelectedLinesKey',
  invoiceCreate = 'invoiceCreateKey',
  job = 'jobKey',
  jobTs = 'jobTsKey',
  personnel = 'personnelKey',
  personnelCalendar = 'personnelCalendarKey',
  purchaseOrder = 'purchaseOrderKey',
  purchaseSummary = 'purchaseSummaryKey',
  request = 'requestKey',
  reviewRequest = 'reviewRequestKey',
  reviewWorkOrderLine = 'reviewTaskKey',
  reviewWorkOrder = 'reviewWorkOrderKey',
  reviewWorkOrders = 'reviewWorkOrdersKey',
  timeSheet = 'bookingKey',
  unassignedTasks = 'unassignedKey',
  unscheduled = 'unscheduledKey',
  workOrder = 'workOrderKey',
  workOrders = 'workOrdersKey',
  workOrderLine = 'taskKey',
  workOrderLineOptions = 'taskOptionsKey',
  timesheetUnscheduled = 'timesheetUnscheduledKey',
  timesheetCreate = 'timesheetCreateKey',
  workOrderTs = 'workOrderTimesheetKey',
  tsManagement = 'timesheetManagementKey',
  tsOperation = 'timesheetOperationKey',
  tsPersonnel = 'timesheetPersonnelKey',
  tsHr = 'timesheetHrKey',
  tsHrPosting = 'timesheetHrPostingKey',
}
